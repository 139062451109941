import React from "react";
import Image from "next/image";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import Button from "@/components/Buttons";
import * as S from "@/styles/index.styled";
import { NO_PHOTO } from "@/utils/constants";

import "swiper/css";
import "swiper/css/pagination";

interface Props {
  data: any;
}

const News = ({ data }: Props) => {
  return (
    <>
      <S.NewsWrapperSlider>
        <Swiper
          pagination={{
            clickable: true
          }}
          spaceBetween={20}
          modules={[Pagination, Autoplay]}
          loop
          autoplay={{
            delay: 7000,
            disableOnInteraction: true
          }}>
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <S.News>
                <S.NewsImageContainer>
                  <Image
                    loading="lazy"
                    placeholder="blur"
                    blurDataURL="data:image/svg+xml;base64,CiAgICA8c3ZnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDggNSc+CiAgICAgIDxmaWx0ZXIgaWQ9J2InIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0nc1JHQic+CiAgICAgICAgPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0nMScgLz4KICAgICAgPC9maWx0ZXI+CgogICAgICA8aW1hZ2UgcHJlc2VydmVBc3BlY3RSYXRpbz0nbm9uZScgZmlsdGVyPSd1cmwoI2IpJyB4PScwJyB5PScwJyBoZWlnaHQ9JzEwMCUnIHdpZHRoPScxMDAlJyAKICAgICAgaHJlZj0nZGF0YTppbWFnZS9hdmlmO2Jhc2U2NCwvOWovMndCREFBZ0dCZ2NHQlFnSEJ3Y0pDUWdLREJRTkRBc0xEQmtTRXc4VUhSb2ZIaDBhSEJ3Z0pDNG5JQ0lzSXh3Y0tEY3BMREF4TkRRMEh5YzVQVGd5UEM0ek5ETC8yd0JEQVFrSkNRd0xEQmdORFJneUlSd2hNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpML3dBQVJDQUFMQUJBREFTSUFBaEVCQXhFQi84UUFGZ0FCQVFFQUFBQUFBQUFBQUFBQUFBQUFCZ01ILzhRQUloQUFBZ0lDQWdFRkFRQUFBQUFBQUFBQUFRSURCQVVSQUNFU0JoTVVNVUhCLzhRQUZRRUJBUUFBQUFBQUFBQUFBQUFBQUFBQUFBTC94QUFaRVFBREFBTUFBQUFBQUFBQUFBQUFBQUFBQVJFQ0lUSC8yZ0FNQXdFQUFoRURFUUEvQU5KdFhNbEZqekxjaGZIMVl4dDVQa3B2ZjUzL0FEWGZJeGVzemtFclJZK3V0eVYxVVNsU3dDc1U4aHM2ME5nRTY0aEVVZCtrOWEzR2swRWkrTG82Z2dnOWNNNTJOYU9GdFdxbzltWlN6cXlIV2pvOWdmWDd3M3VsNHpoLy85az0nIC8+CiAgICA8L3N2Zz4KICA="
                    src={`${item.image.url || NO_PHOTO}`}
                    alt="картинка новости"
                    width={654}
                    height={286}
                    style={{
                      objectFit: "contain",
                      width: 0,
                      height: 0,
                      minWidth: "100%",
                      maxWidth: "100%",
                      minHeight: "100%",
                      maxHeight: "100%",
                      position: "absolute",
                      zIndex: 2
                    }}
                  />
                  {item.image?.url && (
                    <S.PlaceholderImage
                      loading="lazy"
                      src={item.image.url}
                      alt="placeholder"
                      width={654}
                      height={286}
                      style={{
                        width: 0,
                        height: 0,
                        minWidth: "100%",
                        maxWidth: "100%",
                        minHeight: "100%",
                        maxHeight: "100%",
                        position: "absolute"
                      }}
                    />
                  )}
                </S.NewsImageContainer>
                <S.NewsContentWrapper>
                  <div>
                    <S.NewsDate>{moment(item.createdAt).format("DD.MM.YYYY")}</S.NewsDate>
                    <S.NewsTitle>{item.title}</S.NewsTitle>
                    <S.NewsText>{item.seo.metaDescription}</S.NewsText>
                  </div>

                  <Button variant="minimal" isLink path={`/news/${item.parentPath}/${item.id}`}>
                    Читать статью
                  </Button>
                </S.NewsContentWrapper>
              </S.News>
            </SwiperSlide>
          ))}
        </Swiper>
      </S.NewsWrapperSlider>
      <S.NewsWrapper>
        {data?.map((item, index) => (
          <S.News key={`${item.parentPath}${item.id}`}>
            <S.NewsImageContainer>
              <Image
                loading="lazy"
                placeholder="blur"
                blurDataURL="data:image/svg+xml;base64,CiAgICA8c3ZnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDggNSc+CiAgICAgIDxmaWx0ZXIgaWQ9J2InIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0nc1JHQic+CiAgICAgICAgPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0nMScgLz4KICAgICAgPC9maWx0ZXI+CgogICAgICA8aW1hZ2UgcHJlc2VydmVBc3BlY3RSYXRpbz0nbm9uZScgZmlsdGVyPSd1cmwoI2IpJyB4PScwJyB5PScwJyBoZWlnaHQ9JzEwMCUnIHdpZHRoPScxMDAlJyAKICAgICAgaHJlZj0nZGF0YTppbWFnZS9hdmlmO2Jhc2U2NCwvOWovMndCREFBZ0dCZ2NHQlFnSEJ3Y0pDUWdLREJRTkRBc0xEQmtTRXc4VUhSb2ZIaDBhSEJ3Z0pDNG5JQ0lzSXh3Y0tEY3BMREF4TkRRMEh5YzVQVGd5UEM0ek5ETC8yd0JEQVFrSkNRd0xEQmdORFJneUlSd2hNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpJeU1qSXlNakl5TWpML3dBQVJDQUFMQUJBREFTSUFBaEVCQXhFQi84UUFGZ0FCQVFFQUFBQUFBQUFBQUFBQUFBQUFCZ01ILzhRQUloQUFBZ0lDQWdFRkFRQUFBQUFBQUFBQUFRSURCQVVSQUNFU0JoTVVNVUhCLzhRQUZRRUJBUUFBQUFBQUFBQUFBQUFBQUFBQUFBTC94QUFaRVFBREFBTUFBQUFBQUFBQUFBQUFBQUFBQVJFQ0lUSC8yZ0FNQXdFQUFoRURFUUEvQU5KdFhNbEZqekxjaGZIMVl4dDVQa3B2ZjUzL0FEWGZJeGVzemtFclJZK3V0eVYxVVNsU3dDc1U4aHM2ME5nRTY0aEVVZCtrOWEzR2swRWkrTG82Z2dnOWNNNTJOYU9GdFdxbzltWlN6cXlIV2pvOWdmWDd3M3VsNHpoLy85az0nIC8+CiAgICA8L3N2Zz4KICA="
                src={`${item.image.url || NO_PHOTO}`}
                alt="картинка новости"
                width={index === 0 ? 654 : 327}
                height={index === 0 ? 286 : 254}
                style={{
                  objectFit: "contain",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  position: "absolute",
                  zIndex: 2
                }}
              />
              {item.image?.url && (
                <S.PlaceholderImage
                  loading="lazy"
                  src={item.image.url}
                  alt="placeholder"
                  width={index === 0 ? 654 : 327}
                  height={index === 0 ? 286 : 254}
                  style={{
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    position: "absolute"
                  }}
                />
              )}
            </S.NewsImageContainer>
            <S.NewsContentWrapper>
              <div>
                <S.NewsDate>{moment(item.createdAt).format("DD.MM.YYYY")}</S.NewsDate>
                <S.NewsTitle>{item.title}</S.NewsTitle>
                <S.NewsText>{item.seo.metaDescription}</S.NewsText>
              </div>

              <Button variant="minimal" isLink path={`/news/${item.parentPath}/${item.id}`}>
                Читать статью
              </Button>
            </S.NewsContentWrapper>
          </S.News>
        ))}
      </S.NewsWrapper>
    </>
  );
};

export default News;
