import styled from "@emotion/styled";
import Image from "next/image";

import { newColors as colors, sizes } from "@/styles/variables";

export const PopularTripCard = styled.div`
  display: flex;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  max-width: calc((100% - 32px) / 2);
  background-color: ${colors.white};
  padding: 20px 30px;
  flex-wrap: nowrap;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  @media (max-width: 1390px) {
    max-width: initial;
  }
  @media (max-width: 640px) {
    max-width: initial;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px;
  }
`;

export const TripImage = styled(Image)`
  width: 150px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
`;

export const TripInfoWrapper = styled.div`
  display: flex;
  width: calc(100% - 150px);
  justify-content: space-between;
  font-size: ${sizes.md};
  color: ${colors.text};
  @media (max-width: 640px) {
    width: initial;
    gap: 30px;
  }
  @media (max-width: 560px) {
    width: initial;
    flex-direction: column;
    gap: 0;
  }
`;
export const TripCountry = styled.span`
  color: ${colors.gray};
`;

export const TripCitiesInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:first-child {
    padding: 14px 0;
  }
`;

export const TripPoint = styled.div``;

export const TripPrice = styled.div`
  font-weight: 600;
  margin-top: 10px;
  @media (max-width: 640px) {
    margin-top: 14px;
  }
  @media (max-width: 560px) {
    margin-top: 10px;
  }
`;

export const SeeTripButtonWrapper = styled.div`
  @media (max-width: 640px) {
    margin-top: 30px;
  }
`;
export const SliderVariantWrapper = styled.div`
  display: flex;
`;
