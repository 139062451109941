import React, { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import Icons from "@/icons";

import * as S from "./styled";

interface IProps {}
const feedback = [
  {
    name: "Виталий",
    date: "07.05.2023",
    rating: "5/5",
    text: "Несколько раз пользовался услугами данной компании. Порадовало отличное качество обслуживания, и чистые и ухоженные автобусы."
  },
  {
    name: "Евгений",
    date: "28.04.2023",
    rating: "5/5",
    text: "Я был впечатлен чистотой и комфортом автобуса, а также отличной работой персонала компании. Они действительно заботятся о каждом пассажире и делают все возможное, чтобы путешествие было приятным и безопасным."
  },
  {
    name: "Ольга",
    date: "22.04.2023",
    rating: "4/5",
    text: "Была небольшая задержка по времени, но в целом поездка прошла хорошо. Комфорт на уровне, персонал вежливый."
  },
  {
    name: "Игорь",
    date: "15.04.2023",
    rating: "5/5",
    text: "Это была прекрасная поездка! Всё точно по расписанию, очень комфортабельные сиденья и дружелюбный персонал."
  },
  {
    name: "Мария",
    date: "09.04.2023",
    rating: "5/5",
    text: "Мне очень понравилось, как организованы поездки. Автобусы чистые, качество обслуживания на высоте!"
  },
  {
    name: "Юлия",
    date: "25.03.2023",
    rating: "5/5",
    text: "Персонал был очень вежлив и внимателен. Поездка прошла без каких-либо проблем, с максимальным комфортом."
  },
  {
    name: "Дмитрий",
    date: "18.03.2023",
    rating: "4/5",
    text: "В целом хорошо, но WiFi не работал в пути, что вызвало некоторые неудобства."
  },
  {
    name: "Екатерина",
    date: "10.03.2023",
    rating: "5/5",
    text: "Отличная поездка! Все было организовано прекрасно от начала и до конца. Так держать!"
  }
];
const Rating = (props) => {
  const stars = useMemo(() => {
    const [current, outOf] = props.rating.split("/").map(Number);
    const fullStars = Array(current).fill(true); // Активные звезды
    const emptyStars = Array(outOf - current).fill(false); // Неактивные звезды
    return [...fullStars, ...emptyStars];
  }, [props.rating]);

  return (
    <S.Rating>
      {stars.map((isActive, index) => (
        <Icons.Rating key={index} width={20} height={20} active={isActive.toString()} />
      ))}
    </S.Rating>
  );
};
const FeedbackHeader = ({ item }) => {
  return (
    <S.FeedbackHeaderCard>
      <S.TextRating>{item.rating}</S.TextRating>
      <Rating rating={item.rating} />
      <S.FeedbackUserName>{item.name}</S.FeedbackUserName>
      <S.FeedbackDate>{item.date}</S.FeedbackDate>
    </S.FeedbackHeaderCard>
  );
};
const Feedback = ({}: IProps) => {
  return (
    <S.FeedbackWrapper>
      <S.FeedbackContent>
        <Swiper
          pagination={{
            clickable: true
          }}
          modules={[Pagination, Autoplay]}
          loop
          autoplay={{
            delay: 7000,
            disableOnInteraction: true
          }}
          observer
          observeParents
          spaceBetween={10}
          slidesPerView={1}
          slidesPerGroup={1}
          breakpoints={{
            640: {
              slidesPerView: 1,
              slidesPerGroup: 1
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3
            }
          }}>
          {feedback.map((item, index) => (
            <SwiperSlide key={index}>
              <S.FeedbackCard>
                <FeedbackHeader item={item} />
                <S.FeedbackText>{item.text}</S.FeedbackText>
              </S.FeedbackCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </S.FeedbackContent>
    </S.FeedbackWrapper>
  );
};

export default Feedback;
