import styled from "@emotion/styled";

import { newColors as colors } from "@/styles/variables";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 60px 0;
`;

export const Anchor = styled.div`
  position: absolute;
  top: -65px;
  left: 0;
`;

export const ColorSection = styled.div<{ white?: boolean }>`
  background-color: ${(props) => (props.white ? "#ffffff" : "#f3f5f6")};
  position: relative;
`;

export const SectionTitle = styled.h3`
  font-size: 20px;
  color: ${colors.text};
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 40px;
`;
