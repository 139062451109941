import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import styled from "@emotion/styled";

import CardRoute from "@/components/pages/main/CardRoute";

import "swiper/css";
import "swiper/css/pagination";

interface Props {
  data: any;
}

const RoutesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px 30px;
  @media (max-width: 1390px) {
    flex-direction: column;
  }

  @media (max-width: 640px) {
    display: none;
  }
`;
const RoutesSectionMobile = styled.div`
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
`;

const Routes = ({ data }: Props) => {
  return (
    <>
      <RoutesSectionMobile style={{ width: "100%" }}>
        <Swiper
          style={{ width: "100%", height: "100%" }}
          pagination={{
            clickable: true
          }}
          spaceBetween={20}
          autoHeight={false}
          modules={[Pagination, Autoplay]}
          loop
          autoplay={{
            delay: 7000,
            disableOnInteraction: true
          }}>
          {data.map((props, index) => (
            <SwiperSlide key={index}>
              <CardRoute mini alt={"Картинка"} {...props} />
            </SwiperSlide>
          ))}
        </Swiper>
      </RoutesSectionMobile>
      <RoutesSection>
        {data.map((props, index) => (
          <CardRoute key={index} alt={"Картинка"} {...props} />
        ))}
      </RoutesSection>
    </>
  );
};

export default Routes;
