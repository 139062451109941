import React from "react";

import Button from "@/components/Buttons";

import * as S from "./styled";

interface IProps {
  price: string;
  imgSrc: string;
  alt: string;
  departure: string;
  departureLatin: string;
  departureCountry: string;
  arrival: string;
  arrivalLatin: string;
  arrivalCountry: string;
  mini: boolean;
}

const CardRoute = ({
  imgSrc,
  alt,
  departure,
  departureCountry,
  arrival,
  arrivalCountry,
  price,
  departureLatin,
  arrivalLatin,
  mini
}: IProps) => {
  if (mini) {
    return (
      <S.PopularTripCard>
        <S.SliderVariantWrapper>
          <S.TripImage quality={100} loading="lazy" placeholder="blur" src={imgSrc} alt={alt} />
          <S.TripInfoWrapper>
            <S.TripCitiesInfo>
              <S.TripPoint>
                {departure} <S.TripCountry>{departureCountry}</S.TripCountry>
              </S.TripPoint>
              <S.TripPoint>
                {arrival} <S.TripCountry>{arrivalCountry}</S.TripCountry>
              </S.TripPoint>
            </S.TripCitiesInfo>
            <S.TripCitiesInfo>
              <S.TripPrice>{`от ${price}`}</S.TripPrice>
            </S.TripCitiesInfo>
          </S.TripInfoWrapper>
        </S.SliderVariantWrapper>
        <S.SeeTripButtonWrapper>
          <Button isLink path={`/bilety-na-avtobus-${departureLatin}--${arrivalLatin}`} maxWidth={300}>
            Найти билет
          </Button>
        </S.SeeTripButtonWrapper>
      </S.PopularTripCard>
    );
  }
  return (
    <S.PopularTripCard>
      <S.TripImage quality={100} loading="lazy" placeholder="blur" src={imgSrc} alt={alt} />
      <S.TripInfoWrapper>
        <S.TripCitiesInfo>
          <S.TripPoint>
            {departure} <S.TripCountry>{departureCountry}</S.TripCountry>
          </S.TripPoint>
          <S.TripPoint>
            {arrival} <S.TripCountry>{arrivalCountry}</S.TripCountry>
          </S.TripPoint>
        </S.TripCitiesInfo>
        <S.TripCitiesInfo>
          <S.TripPrice>{`от ${price}`}</S.TripPrice>
          <S.SeeTripButtonWrapper>
            <Button isLink path={`/bilety-na-avtobus-${departureLatin}--${arrivalLatin}`} maxWidth={300}>
              Найти билет
            </Button>
          </S.SeeTripButtonWrapper>
        </S.TripCitiesInfo>
      </S.TripInfoWrapper>
    </S.PopularTripCard>
  );
};

export default CardRoute;
