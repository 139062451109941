import styled from "@emotion/styled";

import { colors } from "@/styles/variables";

export const FAQWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  @media (max-width: 750px) {
    flex-direction: column;
    height: initial;
    align-items: center;
  }
`;
export const AnchorLink = styled.div`
  position: absolute;
  top: -70px;
  left: 0;
`;

export const FAQTitle = styled.div`
  position: absolute;
  left: 50px;
  top: 20px;
  color: ${colors.primary600};
  font-size: 16px;
  font-weight: bold;
  display: block;

  @media (max-width: 750px) {
    left: 25px;
  }
`;

export const FAQContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FAQItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
export const FAQItemQuestion = styled.h4`
  font-weight: bold;
  font-size: 16px;
  color: ${colors.neutral900};
`;
export const FAQItemAnswer = styled.p`
  font-size: 14px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ShowMore = styled.button`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: underline;
  transition: color 0.15s;
  &:hover {
    color: ${colors.primary600};
  }
`;
