import React, { useMemo } from "react";
import _ from "lodash";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

import { newColors as colors } from "@/styles/variables";

import * as S from "./styled";

interface IProps {
  data: Faq[];
}

interface Faq {
  id: number;
  __component: string;
  question: string;
  answer: string;
}
const splitArrayInHalf = (arr: Faq[]) => {
  if (!_.isArray(arr)) {
    return [[], []];
  }

  const middleIndex = Math.ceil(arr.length / 2);
  const firstHalf = arr.slice(0, middleIndex);
  const secondHalf = arr.slice(middleIndex);

  return [firstHalf, secondHalf];
};

const FAQ = ({ data }: IProps) => {
  if (!data) return null;
  const content = useMemo(() => splitArrayInHalf(data), [data]);

  return (
    <S.FAQWrapper>
      <S.Column>
        {content[0].map((item, index) => (
          <Accordion allowToggle style={{ width: "100%", maxWidth: "650px" }} key={index}>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" color={colors.mainBlue} />
                      ) : (
                        <AddIcon fontSize="12px" color={colors.mainBlue} />
                      )}
                      <Box as="span" flex="1" textAlign="left" style={{ marginLeft: "15px", fontWeight: 700 }}>
                        {item.question}
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel style={{ paddingLeft: "43px" }} pb={4}>
                    {item.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        ))}
      </S.Column>
      <S.Column>
        {content[1].map((item, index) => (
          <Accordion allowToggle style={{ width: "100%", maxWidth: "650px" }} key={index}>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" color={colors.mainBlue} />
                      ) : (
                        <AddIcon fontSize="12px" color={colors.mainBlue} />
                      )}
                      <Box as="span" flex="1" textAlign="left" style={{ marginLeft: "15px", fontWeight: 700 }}>
                        {item.question}
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel style={{ paddingLeft: "43px" }} pb={4}>
                    {item.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        ))}
      </S.Column>
    </S.FAQWrapper>
  );
};

export default FAQ;
