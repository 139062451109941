import React from "react";

import { ContentWrapperTag } from "@/styles/pages";

import * as S from "./styled";

interface Props {
  children: React.ReactNode;
  name: string;
  white?: boolean;
  id?: string;
}

const Section = ({ children, name, white, id }: Props) => {
  return (
    <S.ColorSection white={white}>
      <S.Anchor id={id} />
      <ContentWrapperTag>
        <S.Section>
          <S.SectionTitle>{name}</S.SectionTitle>
          {children}
        </S.Section>
      </ContentWrapperTag>
    </S.ColorSection>
  );
};

export default Section;
