import styled from "@emotion/styled";

import { newColors as colors, styles } from "@/styles/variables";

export const FeedbackWrapper = styled.div``;
export const FeedbackContent = styled.div``;
export const FeedbackCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.border};
  box-shadow: ${styles.shadow};
  border-radius: ${styles.br};
  color: ${colors.gray};
  padding: 20px;
  max-width: 425px;
  min-height: 200px;
  @media (max-width: 768px) {
    max-width: initial;
  }
`;
export const FeedbackHeaderCard = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
`;

export const TextRating = styled.div`
  margin-right: 10px;

  @media (max-width: 450px) {
    display: none;
  }
`;
export const FeedbackText = styled.div`
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const FeedbackUserName = styled.span`
  display: block;
  margin-left: 24px;
  @media (max-width: 450px) {
    margin-left: 10px;
  }
`;

export const FeedbackDate = styled.span`
  display: block;
  margin-left: 24px;
  @media (max-width: 450px) {
    margin-left: 10px;
  }
`;
